<template>
  <v-menu
    offset-y
    left
    bottom
    :close-on-content-click="false"
    v-model="menuOpen"
    offset-overflow
  >
    <template #activator="{ on, attrs }">
      <eva-button
        dense
        v-on="on"
        v-bind="attrs"
        v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.buttonTooltip')"
        class="pa-2"
      >
        <v-icon small>
          mdi-creation-outline
        </v-icon>
      </eva-button>
    </template>

    <v-card
      width="400px"
    >
      <v-card-text>
        <template v-if="showLoader">
          <v-skeleton-loader
            v-for="i in 7"
            :key="i"
            type="text"
            class="mb-0"
            :loading="false"
            :width="`${
              Math.floor(Math.random() * 50) + 50
            }%`"
          />
        </template>
        <template v-else>
          <v-card
            outlined
            flat
            class="pa-2"
            style="max-height: 300px; height: 300px; overflow: scroll;"
          >
            <katex-md
              :expr="htmlSuggestion"
            />
          </v-card>

          <div class="mt-2">
            <b>{{ $t("evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.gradeSuggestion") }}</b>
            <template v-if="gradingCriteria.length > 0">
              {{ gradeSuggestionFromCriteria }}
            </template>
            <template v-else>
              {{ feedbackSuggestion.min_grade }} → {{ feedbackSuggestion.max_grade }}
            </template>
          </div>

          <template v-if="gradingCriteria.length > 0">
            <v-divider class="my-2" />

            <div
              v-for="criterion in feedbackSuggestion.grade_by_criterion"
              :key="criterion.uuid"
              class="mb-2"
            >
              <b>{{ criterion.name }}</b>: {{ criterion.grade }}% | Ponderation : {{ question.attributes.grading_criteria.find((c) => c.uuid === criterion.uuid)?.weight }}%
              <br>
              <span class="grey--text font-weight-medium">
                {{ criterion.comment }}
              </span>
            </div>
          </template>
        </template>
      </v-card-text>
      <template v-if="rated && !userFeedbackSent">
        <v-divider />
        <v-sheet color="grey lighten-3">
          <v-card-text>
            <v-textarea
              v-model="userFeedback"
              dense
              auto-grow
              solo
              filled
              flat
              hide-details
              class="mb-2"
              :label="rated < 0 ? $t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.userFeedback.unhelpful') : $t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.userFeedback.helpful')"
            />
            <div class="d-flex">
              <v-spacer />

              <eva-button
                dense
                primary
                @click="submitUserFeedback"
              >
                <v-icon
                  small
                >
                  mdi-send-outline
                </v-icon>
              </eva-button>
            </div>
          </v-card-text>
        </v-sheet>
        <v-divider class="pb-4" />
      </template>
      <v-card-actions class="px-4 pt-0">
        <template v-if="llmResponseLog">
          <eva-button
            dense
            @click="generateFeedback"
            v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.regenerate')"
          >
            <v-icon small>
              mdi-refresh
            </v-icon>
          </eva-button>
          <eva-button
            v-if="!rated"
            dense
            @click="rateSuggestion(-1)"
            v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.unhelpful')"
          >
            <v-icon small>
              mdi-thumb-down-outline
            </v-icon>
          </eva-button>
          <eva-button
            v-if="!rated"
            dense
            @click="rateSuggestion(1)"
            v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.helpful')"
          >
            <v-icon small>
              mdi-thumb-up-outline
            </v-icon>
          </eva-button>
        </template>
        <v-spacer />
        <keyboard-tooltip
          :key-name="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.insert.key')"
          :label-after="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.insert.tooltip')"
        >
          <eva-button
            primary
            :label="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.insert.label')"
            dense
            @click="insert"
          />
        </keyboard-tooltip>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>

import { computed, ref, watch } from "vue"
import KatexMd from "@/components/katex-md.vue"
import axios from "axios"
import jsonApii from "@/helpers/json_apii"
import KeyboardTooltip from "@/components/shared/keyboard_tooltip.vue"
import { onKeyStroke } from "@vueuse/core"
import markdown_it from "markdown-it"
import mk from "@traptitech/markdown-it-katex"

export default {
  name: "GenerateFeedbackMenu",
  components: { KeyboardTooltip, KatexMd },
  props: {
    answer: { type: Object, required: true },
    question: { type: Object, required: true },
  },
  emits: ["insert", "update-grade-suggestion"],
  setup(props, { emit }) {
    const showLoader = ref(true)
    const menuOpen = ref(false)
    const feedbackSuggestion = ref({})
    const llmResponseLog = ref(null)
    const rated = ref(null)
    const userFeedback = ref("")
    const userFeedbackSent = ref(false)

    const generateFeedback = () => {
      showLoader.value = true
      axios.post(props.answer.links.feedback_suggestion).then((response) => {
        showLoader.value = false
        // feedbackSuggestion.value = mdToHtml(
        //   jsonApii.getData(response.data).content
        // )
        feedbackSuggestion.value = jsonApii.getData(response.data).content
        llmResponseLog.value = jsonApii.getData(response.data)
        rated.value = null
        userFeedbackSent.value = false
        emitGradeSuggestion()
      })
    }

    const gradingCriteria = computed(() => {
      return props.question.attributes.grading_criteria || []
    })

    const emitGradeSuggestion = () => {
      const gradeSuggestion = gradingCriteria.value.length > 0 ? gradeSuggestionFromCriteria.value : gradeSuggestionWithoutCriteria.value
      emit("update-grade-suggestion", Math.round((gradeSuggestion) * 4) / 4)
    }

    const mdToHtml = (string) => {
      const md = markdown_it({
        html: true,
        typography: true,
        breaks: false,
      })
      md.use(mk)
      return md.render(string)
    }

    const htmlSuggestion = computed(() => {
      return mdToHtml(feedbackSuggestion.value.content)
    })

    const gradeSuggestionWithoutCriteria = computed(() => {
      return (feedbackSuggestion.value.max_grade + feedbackSuggestion.value.min_grade) / 2
    })

    const gradeSuggestionFromCriteria = computed(() => {
      return feedbackSuggestion.value.grade_by_criterion.reduce((acc, criterion) => {
        return acc + criterion.grade * gradingCriteria.value.find((c) => c.uuid === criterion.uuid)?.weight
      }, 0) / 100 / 100 * props.question.attributes.coefficient
    })

    const rateSuggestion = (rate) => {
      if (!llmResponseLog.value) return
      axios
        .patch(llmResponseLog.value.links.self, { llm_response_log : { user_rating: rate } })
        .then(() => {
          rated.value = rate
        })
    }

    const submitUserFeedback = () => {
      if (!llmResponseLog.value) return
      axios
        .patch(llmResponseLog.value.links.self, { llm_response_log : { user_feedback: userFeedback.value } })
        .then(() => {
          rated.value = true
          userFeedback.value = ""
          userFeedbackSent.value = true
        })
    }

    const insert = () => {
      if (!feedbackSuggestion.value.content) return
      emit("insert", htmlSuggestion.value)
      menuOpen.value = false
    }

    onKeyStroke("Enter", (e) => {
      if (menuOpen.value) {
        e.preventDefault()
        insert()
      }
    })

    watch(menuOpen, (value) => {
      if (value) {
        feedbackSuggestion.value = {}
        generateFeedback()
      }
    })
    return {
      showLoader,
      menuOpen,
      generateFeedback,
      feedbackSuggestion,
      htmlSuggestion,
      insert,
      rateSuggestion,
      llmResponseLog,
      userFeedback,
      rated,
      submitUserFeedback,
      userFeedbackSent,
      gradeSuggestionFromCriteria,
      gradingCriteria,
    }
  },
}
</script>
